.vermieter-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/vermieter.webp);
	}

	.owl-dots {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		margin-top: 20px;

		@media (max-width: 767px) {
			margin: 0 auto;
		}

		.owl-dot {
			width: 15px;
			height: 15px;
			background: #b5b5b5;
			margin: 0 5px;
			border-radius: 100px;

			&.active {
				background: var(--color-primary);
			}
		}
	}

	.usp-desk {
		.cust-row {
			--bs-gutter-x: 1.5rem;
			--bs-gutter-y: 0;
			display: -webkit-box;
			display: flex;
			flex-wrap: wrap;
			margin-top: calc(-1* var(--bs-gutter-y));
			margin-right: calc(-0.5* var(--bs-gutter-x));
			margin-left: calc(-0.5* var(--bs-gutter-x));
		}

		.cust-row>* {
			flex-shrink: 0;
			width: 100%;
			max-width: 100%;
			padding-right: calc(var(--bs-gutter-x)* 0.5);
			padding-left: calc(var(--bs-gutter-x)* 0.5);
			margin-top: var(--bs-gutter-y);
		}

		@media (min-width: 768px) {
			.cust-col-md-4 {
				/* width: 33.33333333%; */
				width: 50%;
			}
		}
	}

	.hide-mob {
		@media (max-width:992px) {
			display: none !important;
		}
	}

	.usp-mob {
		.feature-slide {}
	}

	.highlights {
		display: flex;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-wrap: wrap;
		}


		.highlight {
			text-align: center;
			color: var(--color-black);
			position: relative;
			background-color: #fff;
			border: 1px solid var(--color-grey-normal);
			transition: all .2s;
			height: 100%;
			border-radius: var(--border-radius);

			/*&:hover {
					box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
					transform: scale(1.015);
				}*/

			.content {
				padding: 10px;
				text-align: center;
				min-height: 168px;
			}

			.icon {
				margin-bottom: 10px;
				margin-top: 20px;
				font-size: 36px;
				color: var(--color-primary);
				height: 54px;
			}

			.title {
				font-size: 20px;
				margin-bottom: 10px;
				font-weight: 600;
			}

			.text {
				font-size: 18px;
				margin: 0 auto;

				@media(max-width: 1023px) {
					max-width: 300px;
				}

			}

			.usp-img {
				max-width: 75px;
				margin: 0 auto;
				padding: 20px 0 0;
			}
		}
	}

	.erfolgSection {
		img {
			/* max-width: 50% !important; */
			max-width: 75px;
			height: auto;
		}
	}

	.img {
		width: 100%;
		height: auto;
		object-fit: cover;
	}

	strong {
		font-weight: 600;
	}

	#lastviewed-units {
		display: none;
	}
}