.ueber-uns-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.ueber-uns {
			background-image: url(RESOURCE/img/inner-banner-ueber-uns.webp);
		}
	}

	.team-group {
		.img-bl {
			width: 100%;
			height: 350px;
		}

		.img-responsive {
			width: 100%;
			height: 100%;
		}

		.team-text {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			min-height: 140px;
			padding: 0 1em;

			span {
				font-weight: 600;
			}
		}
	}


	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}


}