.static-v1 {
	.inner-banner {
		&.social-media {
			background-image: url(RESOURCE/img/innner-banner-social-media.webp);
		}

		&.nachhaltigkeit {
			background-image: url(RESOURCE/img/inner-banner-nachhaltigkeit.webp);
		}

		&.offene-stellen {
			background-image: url(RESOURCE/img/inner-banner-offene-stellen.webp);
		}

		&.leistungen {
			background-image: url(RESOURCE/img/inner-banner-leistungen.webp);
		}
	}

	.pdf-link {
		display: flex;
		align-items: center;

		a {

			&:before {
				content: "";
				background-image: url(RESOURCE/img/icon-pdf.webp);
				width: 20px;
				height: 25px;
				display: inline-block;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				margin-right: 5px;
			}

		}
	}
}